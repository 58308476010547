import chorus from '../chorus';
import Backbone from '../vendor/backbone';
import $ from '../jquery';
import { UserManager, Log } from 'oidc-client-ts';

// Set to true when silentRenew fails with a login required error
let loginRequired = false;

const authSync = fn => (method, model, options) => {
  const withToken = (token) => {
    const optionsWithAuth = Object.assign({}, options, {
      beforeSend(xhr) {
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        if (options.beforeSend) {
          options.beforeSend(xhr);
        }
      },
    });
    return fn(method, model, optionsWithAuth);
  };
  if (options.token) {
    return withToken(options.token);
  }
  return idToken().then(withToken).catch((error) => {
    if (/4\d{2}/.test(error.status)) {
      console.log(`endpoint unavailable: ${error.statusText}`);
    } else if (error.errorCode === 'consent_required'
      || error.errorCode === 'interaction_required'
      || error.errorCode === 'login_required') {
      $.jGrowl(error.errorMessage);
      signinRedirect();
    } else if (error.errorMessage) {
      console.log(`Error while authenticating with azure: ${error.errorMessage}`);
      $.jGrowl(error.errorMessage);
    } else if (error.responseJSON) {
      console.log(error.responseJSON);
    }
    throw error;
  });
};

const createUserManager = () => {
  const { protocol, host, hash } = window.location;
  const authority = '/'
  const redirectUri = `${protocol}//${host}/${hash}`;
  const silentRenew = `${protocol}//${host}/silent.html`;

  const settings = {
    authority,
    client_id: 'workspaces',
    redirect_uri: redirectUri,
    response_type: 'code',
    post_logout_redirect_uri: `${protocol}//${host}/#`,
    automaticSilentRenew: true,
    monitorSession: true,
    silent_redirect_uri: silentRenew,
  };
  return new UserManager(settings);
};

const handleSignInFailure = (error) => {
  if (error.message === 'login_required' || error.message === 'invalid_token' || error.message === 'invalid_grant') {
    loginRequired = true;
    window.user = null;
  }
};

export const signinRedirect = () => {
  return window.userManager.signinRedirect();
};

let authenticateFn = async () => {
  userManager = createUserManager();
  let user = null;
  userManager.events.addSilentRenewError(handleSignInFailure);
  userManager.events.addUserSignedOut(function(){
    userManager.signinRedirect();
  });

  if (window.location.href.includes('code=')) {
    const hash = window.location.href.match(/#.*$/);
    const redirectURL = window.location.href.replace(/\?state=.*$/,hash);
    const callBackUrl = window.location.href.replace(/#.*$/,'');
    user = await userManager.signinRedirectCallback(callBackUrl);
    window.location.replace(redirectURL);
  }

  if (!user) {
    user = await userManager.getUser();
  }

  const logOut = window.location.hash.startsWith('#/logout');
  if (logOut) {
    return 'Logout';
  }

  if (user && !user.expired) {
    return user;
  }

  await signinRedirect();

  return null;
};

const loginAfterErrorFn = async () => {
  userManager = createUserManager();
  await signinRedirect();
};

const select = (...list) => (object) => {
  const result = {};
  const emailExists = object.hasOwnProperty('email');
  if (emailExists) {
    object.preferred_username = object.email;
  }
  list.forEach((item) => { result[item] = object[item]; });
  return result;
};

const selectProfile = select('name', 'email', 'preferred_username', 'oid', 'given_name', 'family_name');
let getUserProfileFn = () => {
  if (loginRequired) {
    throw new Error('Login required');
  }
  return userManager.getUser().then(u => selectProfile(u.profile));
};

let azureInitializeFn = async () => {
  user = await userManager.getUser();
  if (user) {
    return idToken().then(() => {
      const { name, oid, preferred_username, email } = user.profile;
      const details = { fullName: name, uuid: oid, email: email || preferred_username };
      chorus.session.set('user', details);
      Backbone.sync = authSync(Backbone.sync)
    }, () => (signinRedirect())); 
  } else {
    signinRedirect();
  }
};

let idTokenFn = () => userManager.getUser().then(user => user.id_token);

let signoutFn = () => userManager.signoutRedirect().catch();

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  if (window.location.hostname === 'localhost' || process.env.NODE_ENV === 'test') {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('examples');
    let dummyUser = {
      name: 'User McUserFace',
      preferred_username: 'user@example.com',
      oid: '12345678-abcd-8765-4331-123456',
      given_name: 'User',
      family_name: 'McUserFace',
    };
    if (name === 'LoginError') {
      authenticateFn = () => Promise.reject(new Error('There was an error'));
    } else {
      // mocking out oauth is really tough with the various redirects and security checks that occur
      // so for dev and test purposes we'll replace oauth entirely
      getUserProfileFn = () => Promise.resolve(dummyUser);
      idTokenFn = () => Promise.resolve('this_is_a_fake');
      authenticateFn = () => getUserProfileFn().then(profile => ({ profile }));
      azureInitializeFn = () => getUserProfileFn().then((dummy) => {
        const details = { fullName: dummy.name, uuid: dummy.oid, email: dummy.preferred_username };
        chorus.session.set('user', details);
      });
      signoutFn = () => {
        dummyUser = null;
        return Promise.resolve({});
      };
    }
  } else {
    // Add these in to get useful debug for authorization
    Log.logger = console;
    Log.level = Log.DEBUG;
  }
}

// User Profile will have the same data values unless logged out, values that change on refresh
// have been filtered out by select static profile values
export const getUserProfile = getUserProfileFn;

// idToken should be kept fresh by the userManager, (service started by startSilentRenew)
export const signout = signoutFn;
export const idToken = idTokenFn;
export const loginAfterError = loginAfterErrorFn;
export const authenticate = authenticateFn;
export const azureInitializeNew = azureInitializeFn;
export default authenticate;
