import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { authenticate } from '../application/auth/oauth';

let init = authenticate;

if (process.env.NODE_ENV === 'development' && window.location.hostname === 'localhost') {
  init = async () => {
    const server = await import('../mock/server');
    server.default();

    return authenticate();
  };
}

// The amount of code initially loaded should be as small as possible so that the redirect to authenticate
// occurs as quickly as possible.
// Require in the App or Error to dynamically load the rest of the code.
async function showUI() {
  const app = await import('../authentication/app');
  app.default();
}

async function showError(error) {
  console.debug(error);
}

if (!!window.MSInputMethodContext && !!document.documentMode) {
  showError();
} else {
  init().then(showUI).catch(showError);
}
