/* eslint-disable no-undef */
function initializeWorkspace() {
  if (typeof chorus === 'object' && typeof chorus.instance === 'object') {
    // chorus starts only when defined due to timing issues, especially when on localhost
    chorus.instance.start();
  } else {
    setTimeout(initializeWorkspace, 100);
  }
}

export default initializeWorkspace;
